import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { LSYTheme } from './src/themes/LSYTheme';
import { FooterContextProvider } from './src/views/Footer/FooterContext';
import { CssBaseline } from '@mui/material';

export const wrapRootElement = ({element}) => {
    return (
        <ThemeProvider theme={LSYTheme}>
            <CssBaseline />
            <FooterContextProvider>
                {element}
            </FooterContextProvider>
        </ThemeProvider>
    );
}



